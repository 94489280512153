import {Container, Row, Col} from 'react-bootstrap';
import colorSharp from '../assets/img/color-sharp.png';

export const Experience = () => {
  return (
    <section className="experience" id="experience">
      <Container>
        <Row>
          <h2>Professional Experience</h2>
          <Col size={12}>
            <div>
              <h4>Orangetoolz</h4>
              <p>Full Stack Engineer</p>
              <p className="small-light-text">SEP 2019 - RUNNING</p>
              <p className="small-light-text">Dhaka, Bangladesh</p>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-left" src={colorSharp} alt="cover" />
      <img
        className="background-image-right background-image-flip-x"
        src={colorSharp}
        alt="cover"
      />
    </section>
  );
};
