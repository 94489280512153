import {Container, Row, Col, Tab, Nav} from 'react-bootstrap';
import {ProjectCard} from './ProjectCard';
import projImg1 from '../assets/img/project-img1.png';
import projImg2 from '../assets/img/project-img2.png';
import projImg4 from '../assets/img/project-img4.png';
import projImg5 from '../assets/img/project-img5.png';
import projImg6 from '../assets/img/project-img6.png';
import colorSharp2 from '../assets/img/color-sharp2.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const features = [
    {
      title: 'Form Builder',
      description:
        'Simplifies form creation and management, streamlining the process for efficient data collection.',
      imgUrl: projImg1,
      link: 'https://form.sariyad.com'
    },
    {
      title: 'Website Builder',
      description: 'Effortlessly create and customize websites with user-friendly tool.',
      imgUrl: projImg2,
      link: 'https://wb.sariyad.com/builder'
    }
  ];

  const projects = [
    {
      title: 'Salespype.com',
      description: 'All in one CRM',
      imgUrl: projImg4,
      link: 'https://salespype.com/'
    },
    {
      title: 'StrongProposals.com',
      description: 'Find new leads with mapping tool',
      imgUrl: projImg5,
      link: 'https://strongproposals.com/'
    },
    {
      title: 'mapYourSales.com',
      description: 'Modern, Digital Insurance Proposals',
      imgUrl: projImg6,
      link: 'https://mapyoursales.com/'
    }
  ];

  const packages = [
    {
      title: 'React Audio Play',
      description: 'A simple audio player component for react.',
      imgUrl:
        'https://opengraph.githubassets.com/b18dc91ae46034926245d7f8f013c15bc47d93d13ee1212828b408f11e83d927/riyaddecoder/react-audio-play',
      link: 'https://riyaddecoder.github.io/react-audio-play/'
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({isVisible}) => (
                <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                  <h2>Projects</h2>
                  <p>
                    I have been working on various projects and features, both inside and outside of
                    my office. I am presenting two features to you: the <b>form builder</b> and the{' '}
                    <b>website builder</b>, as these are the ones I worked on most recently.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Features</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Projects</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Packages</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={isVisible ? 'animate__animated animate__slideInUp' : ''}
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {features.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {packages.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" alt="the background" src={colorSharp2}></img>
    </section>
  );
};
