import {Container, Row, Col} from 'react-bootstrap';
import colorSharp from '../assets/img/color-sharp.png';

export const Achievement = () => {
  return (
    <section className="experience" id="achievement">
      <Container>
        <Row>
          <h2>Achievements</h2>
          <Col size={12}>
            <div>
              <h4>Nasa Space Apps Challenge 2018</h4>
              <p>
                Bangladesh Division Winner -{' '}
                <a
                  href="https://2018.spaceappschallenge.org/locations/chittagong/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Team Queue
                </a>
              </p>
              <p className="small-light-text">October 2018</p>
              <p className="small-light-text">Dhaka, Bangladesh</p>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-left" src={colorSharp} alt="cover" />
      <img
        className="background-image-right background-image-flip-x"
        src={colorSharp}
        alt="cover"
      />
    </section>
  );
};
