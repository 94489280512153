import { Container, Row, Col } from "react-bootstrap";
// import { MailchimpForm } from "./MailchimpForm";
// import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import githubIcon from '../assets/img/nav-icon-github.svg';


export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            {/* <img src={logo} alt="Logo" /> */}
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end pt-5">
            <div className="social-icon">
              <a
                  href="https://www.linkedin.com/in/riyaddecoder/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <img src={navIcon1} alt="Icon" />
                </a>
                <a href="https://www.fb.me/riyaddecoder" target={'_blank'} rel="noreferrer">
                  <img src={navIcon2} alt="Icon" />
                </a>
                <a href="https://www.github.com/riyaddecoder/" target={'_blank'} rel="noreferrer">
                  <img src={githubIcon} alt="" />
                </a>
            </div>
            <p>Copyright {new Date().getFullYear()}. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
