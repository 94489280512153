import {Container, Row, Col} from 'react-bootstrap';
import colorSharp2 from '../assets/img/color-sharp2.png';
import uniImage from '../assets/img/uni-image.png';

export const Education = () => {
  return (
    <section className="education" id="education">
      <Container>
        <Row>
          <h2>Education</h2>
          <Col size={12}>
            <div>
              <Row>
                <Col size={6} style={{margin: 'auto'}}>
                  <div>
                    <h4>BSc in Computer Science and Engineering</h4>
                    <p>Premier University Chittagong</p>
                    <p className="small-light-text">JAN 2015 - MAR 2019</p>
                    <p className="small-light-text">Chittagong, Bangladesh</p>
                  </div>
                </Col>
                <Col size={6}>
                  <img src={uniImage} alt="uniImage" />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" alt="the background" src={colorSharp2}></img>
    </section>
  );
};
