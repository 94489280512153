import {useState} from 'react';
import {useForm} from 'react-hook-form';
import TrackVisibility from 'react-on-screen';
import {Container, Row, Col} from 'react-bootstrap';
import contactImg from '../assets/img/contact-img.svg';
import CustomToaster from '../common/Toaster.jsx/CustomToaster';
import 'animate.css';

export const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
    reset
  } = useForm();

  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState();

  const handleFormSubmit = async data => {
    setButtonText('Sending...');
    try {
      let response = await fetch(
        `${
          process.env.NODE_ENV === 'production'
            ? 'https://profile-api.sariyad.com'
            : 'http://localhost:5000'
        }/get-in-touch`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(data)
        }
      );
      let result = await response.json();
      if (response.status === 200) {
        reset();
        setStatus({success: true, message: result.message});
      } else if (response.status === 400) {
        for (let key in result.error) {
          setError(key, {message: result.error[key]});
        }
      } else {
        setStatus({success: false, message: result.message});
      }
    } catch (e) {
      setStatus({success: false, message: 'Something went wrong. Please try again later.'});
    } finally {
      setButtonText('Send');
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({isVisible}) => (
                <img
                  className={isVisible ? 'animate__animated animate__zoomIn' : ''}
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({isVisible}) => (
                <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          placeholder="First Name"
                          className={errors.firstName ? 'border-danger' : ''}
                          {...register('firstName')}
                        />
                        {errors.firstName?.message && (
                          <p className="small text-danger">{errors.firstName.message}</p>
                        )}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          placeholder="Last Name"
                          className={errors.lastName ? 'border-danger' : ''}
                          {...register('lastName')}
                        />
                        {errors.lastName?.message && (
                          <p className="small text-danger">{errors.lastName.message}</p>
                        )}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="email"
                          placeholder="Email Address"
                          className={errors.email ? 'border-danger' : ''}
                          {...register('email', {
                            required: {
                              value: true,
                              message: 'Email is required'
                            },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: 'Invalid email address'
                            }
                          })}
                        />
                        {errors.email?.message && (
                          <p className="small text-danger">{errors.email.message}</p>
                        )}
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="tel"
                          placeholder="Phone No."
                          className={errors.phone ? 'border-danger' : ''}
                          {...register('phone')}
                        />
                        {errors.phone?.message && (
                          <p className="small text-danger">{errors.phone.message}</p>
                        )}
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea
                          rows="6"
                          placeholder="Message"
                          className={errors.message?.message ? 'border-danger' : ''}
                          {...register('message', {
                            required: {
                              value: true,
                              message: 'Message is required'
                            }
                          })}
                        ></textarea>
                        {errors.message?.message && (
                          <p className="small text-danger">{errors.message.message}</p>
                        )}

                        <button type="submit">
                          <span>{buttonText}</span>
                        </button>
                      </Col>
                      <CustomToaster
                        onClose={() => setStatus(null)}
                        message={status?.message}
                        variant={status?.success ? 'success' : 'danger'}
                      />
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
