import colorSharp from '../assets/img/color-sharp.png';

export const Skills = () => {
  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
                4+ years of experience building robust web applications with Laravel and React.
                Proven expertise in back-end development, API creation, and front-end optimization.
                Eager to leverage my diverse skillset to contribute to a challenging and rewarding
                opportunity.
              </p>

              <div className="skill-progress-container row px-md-1 mx-md-1 px-lg-5 mx-lg-5">
                <div className="row p-3 px-lg-5">
                  <h5 className="col-6">React.JS & NextJS</h5>
                  <div className="skill-progress-bar col-6 p-0">
                    <div className="skill-progress skill-progress-95" />
                  </div>
                </div>
                <div className="row p-3 px-lg-5">
                  <h5 className="col-6">Laravel</h5>
                  <div className="skill-progress-bar col-6 p-0">
                    <div className="skill-progress skill-progress-90" />
                  </div>
                </div>
                <div className="row p-3 px-lg-5">
                  <h5 className="col-6">Node.js</h5>
                  <div className="skill-progress-bar col-6 p-0">
                    <div className="skill-progress skill-progress-30" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="cover" />
      <img className="background-image-right background-image-flip-x" src={colorSharp} alt="cover" />
    </section>
  );
};
