import {useEffect, useState} from 'react';
import {ToastContainer, Toast} from 'react-bootstrap';
import {X} from 'react-bootstrap-icons';
import './customToaster.css';

const CustomToaster = ({message = '', variant = 'danger', header = 'Server Response', onClose}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
    }
  }, [message]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <ToastContainer position="bottom-end" className="position-fixed custom-toaster-container">
      <Toast bg={variant} show={show} onClose={handleClose} autohide delay={3000}>
        <Toast.Header closeButton={false}>
          <strong className="me-auto">{header}</strong>
          <small>Now</small>
          <button
            onClick={handleClose}
            type="button"
            className="m-0 custom-toaster-close-button"
            aria-label="Close"
          >
            <X />
          </button>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default CustomToaster;
